import BigNumber from "bignumber.js";

export const IGNITION_DEX_COMPONENT_ADDRESS = 'component_rdx1cputx5thrnh29mup6ajz0x7v90g4dznvxwzwzvd9ngg8tuqvqlxmlh';
export const IGNITION_NFT_ADDRESS = 'resource_rdx1ntmgj3amlsrj0qxzqwzlk99d7g0xkzv6mg8vd5egawvgd8nt5ypwa7';
export const IGNITION_COMPONENT_ADDRESS = 'component_rdx1cqplswlzpvw9yx687mcnvjuguy24veqk4c55rscjxl3pll7rxfs2dz';

export const IGNITION_PRICES_KV = 'internal_keyvaluestore_rdx1krgydklt34smhcfg6fmqgg3jwluhhngh4rrh3xdx0qld4ep7k3r82s';
export const IGNITON_VOLATILE_VAULT = 'internal_vault_rdx1tr9wegm6fu3krwcevgquy3sz9xuxukum249nz6qxmeqrp87jlp57uk';
export const IGNITON_NONVOLATILE_VAULT = 'internal_vault_rdx1trrlvxg4f0ewfayzgwgadz6usndgv9awx2u8ssyvzrw6y6j49f33k7';

export const IGNITON_TOKENS = [
    'resource_rdx1t4upr78guuapv5ept7d7ptekk9mqhy605zgms33mcszen8l9fac8vf', // xUSDC
    'resource_rdx1thrvr3xfs2tarm2dl9emvs26vjqxu6mqvfgvqjne940jv0lnrrg7rw', // xUSDT
    'resource_rdx1t580qxc7upat7lww4l2c4jckacafjeudxj5wpjrrct0p3e82sq4y75', //xw BTC
    'resource_rdx1th88qcj5syl9ghka2g9l7tw497vy5x6zaatyvgfkwcfe8n9jt2npww' // xETH
]

export const POOL_TPES = {
    resource_rdx1t4upr78guuapv5ept7d7ptekk9mqhy605zgms33mcszen8l9fac8vf: 'nonvolatile',
    resource_rdx1t580qxc7upat7lww4l2c4jckacafjeudxj5wpjrrct0p3e82sq4y75: 'volatile',
    resource_rdx1th88qcj5syl9ghka2g9l7tw497vy5x6zaatyvgfkwcfe8n9jt2npww: 'volatile',
    resource_rdx1thrvr3xfs2tarm2dl9emvs26vjqxu6mqvfgvqjne940jv0lnrrg7rw: 'nonvolatile',
};

export const IGNITION_LOCKUPS = [
    {
        months: 9,
        seconds: 23670144,
        reward: 0.125
    },
    {
        months: 10,
        seconds: 26300160,
        reward: 0.145
    },
    {
        months: 11,
        seconds: 28930176,
        reward: 0.17
    },
    {
        months: 12,
        seconds: 31560192,
        reward: 0.2
    }
]

export function generateOpenIgnitionManifest(pair: any, user_account_address: string, user_resource_address: string, amount: BigNumber, lockup: number) {

    return `
CALL_METHOD
    Address("${user_account_address}")
    "withdraw"
    Address("${user_resource_address}")
    Decimal("${amount.toFixed(pair.Token.divisibility)}")
;

TAKE_FROM_WORKTOP
    Address("${user_resource_address}")
    Decimal("${amount.toFixed(pair.Token.divisibility)}")
    Bucket("bucket")
;

CALL_METHOD
    Address("${IGNITION_COMPONENT_ADDRESS}")
    "open_liquidity_position"
    Bucket("bucket")
    Address("${pair.Pair.address}")
    ${lockup}u64 # The lockup period the user wants to have in seconds.
;

CALL_METHOD
    Address("${user_account_address}")
    "try_deposit_batch_or_abort"
    Expression("ENTIRE_WORKTOP")
    None
;
`
}

export function generateCloseIgnitionManifest(user_account_address: string, liquidity_receipt_non_fungible_local_id: string) {

    return `
CALL_METHOD
    Address("${user_account_address}")
    "withdraw_non_fungibles"
    Address("${IGNITION_NFT_ADDRESS}")
    Array<NonFungibleLocalId>(
        NonFungibleLocalId("${liquidity_receipt_non_fungible_local_id}")
    )
;

TAKE_NON_FUNGIBLES_FROM_WORKTOP
    Address("${IGNITION_NFT_ADDRESS}")
    Array<NonFungibleLocalId>(
        NonFungibleLocalId("${liquidity_receipt_non_fungible_local_id}")
    )
    Bucket("bucket")
;

CALL_METHOD
    Address("${IGNITION_COMPONENT_ADDRESS}")
    "close_liquidity_position"
    Bucket("bucket")
;

CALL_METHOD
    Address("${user_account_address}")
    "try_deposit_batch_or_abort"
    Expression("ENTIRE_WORKTOP")
    None
;
`
}